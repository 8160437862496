// HomeData.js

export const headerData = {
  logo: 'SaaSPro',  // Replace 'SaaSPro' with your application's name
  links: [
    { href: '#about', label: 'About Us' },
    { href: '#features', label: 'Features' },
    { href: '#services', label: 'Services' },
    { href: '#contact', label: 'Contact Us' },
  ],
};

export const heroData = {
  logo: 'SaaSPro',
  title: 'Welcome to SaaSPro',
  description: 'SaaSPro is designed to simplify your workflows, enhance productivity, and provide seamless integrations. Achieve your business goals faster and more efficiently with our platform.',
  buttonText: 'Get Started',
};

export const servicesData = {
  title: 'Why Choose SaaSPro?',
  services: [
    {
      icon: 'FaCogs',  // Replace with relevant icon
      title: 'Customizable Solutions',
      description: 'Tailor our platform to meet the specific needs of your business, ensuring maximum efficiency.',
    },
    {
      icon: 'FaRocket',  // Replace with relevant icon
      title: 'Fast Integration',
      description: 'Integrate seamlessly with your existing tools and systems in no time, boosting productivity instantly.',
    },
    {
      icon: 'FaLock',  // Replace with relevant icon
      title: 'Secure and Reliable',
      description: 'Built with enterprise-grade security features to keep your data safe and compliant.',
    },
    {
      icon: 'FaChartLine',  // Replace with relevant icon
      title: 'Scalable Growth',
      description: 'Easily scale your operations as your business grows with our robust, cloud-based platform.',
    },
  ],
};

export const aboutData = {
  title: 'About SaaSPro',
  description:
    'SaaSPro is a state-of-the-art SaaS platform created to streamline business processes and accelerate growth. Whether you are managing teams, projects, or customer data, SaaSPro offers the tools you need to succeed in today’s fast-paced environment. Our platform is built for businesses of all sizes, providing a scalable solution that evolves with your needs.',
};

export const contactData = {
  title: 'Get in Touch',
  formFields: [
    { label: 'Name', type: 'text', placeholder: 'Your Name' },
    { label: 'Email', type: 'email', placeholder: 'Your Email' },
    { label: 'Message', type: 'textarea', placeholder: 'Your Message' },
  ],
  buttonText: 'Send Message',
  address: {
    title: 'Our Office',
    details: [
      'SaaSPro HQ, 123 Business Rd, Tech City, TX',
      'Email: support@appname.com',
      'Phone: +1 800 123 4567',
    ],
  },
};

export const footerData = {
  columns: {
    company: {
      title: 'Company',
      links: [
        { label: 'About Us', href: '#about' },
        { label: 'Careers', href: '#careers' },
        { label: 'Privacy Policy', href: '#privacy' },
      ],
    },
    services: {
      title: 'Features',
      links: [
        { label: 'Customizable Solutions', href: '#features' },
        { label: 'Fast Integration', href: '#features' },
        { label: 'Scalable Growth', href: '#features' },
      ],
    },
    social: {
      title: 'Follow Us',
      links: [
        { label: 'LinkedIn', href: 'https://www.linkedin.com' },
        { label: 'Twitter', href: 'https://www.twitter.com' },
        { label: 'Facebook', href: 'https://www.facebook.com' },
      ],
    },
  },
  copyright: '© 2024 SaaSPro. All Rights Reserved.',
};
