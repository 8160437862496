// App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Landing from './components/landing/Landing';
import MainLayout from './components/MainLayout';

function App() {
  return (
    <Auth0Provider
      domain="colate-saas.us.auth0.com"
      clientId="TNFwwYSTjfPua0JuWaxdoYrXPC8dMBPP"
      redirectUri={`${window.location.origin}`}
      audience="https://auth.co-create.colate.io"
      scope="openid profile email"
    >
      <Router>
        <Routes>
          {/* Landing page route */}
          <Route path="/" element={<Landing />} />

          {/* Main layout route to handle all other routes */}
          <Route path="/*" element={<MainLayout />} />
        </Routes>
      </Router>
    </Auth0Provider>
  );
}

export default App;
